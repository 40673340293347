<template src="./ViewOffer.html">

</template>

<script>
import jobsService from "@/services/Jobs";
import { roleName } from "@/utils/constants";
import currency from "@/filters/currency";
import historyService from "@/services/History"

export default {
  name: "ViewOffer",
  data() {
    return {
      offerData: {},
      applicant: false,
      jobOfferApplication: {},
      roleName,
      role: null,
      currency
    }
  },
  methods: {
    confirmDeleteOffer() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar oferta laboral',
        message: '¿Estás seguro que quieres eliminar esta oferta de la Ciudadela?',
        confirmText: 'Aceptar',
        cancelText: 'Cancelar',
        onConfirm: () => this.$buefy.toast.open('Account deleted!')
      })
    },
    DeleteApplication() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar aplicación',
        message: '¿Estás seguro que quieres eliminar la aplicación a esta oferta laboral?',
        confirmText: 'Aceptar',
        cancelText: 'Cancelar',
        onConfirm: async () => {
          try {
            await jobsService.quitJob(this.jobOfferApplication.id, this.$store.getters.getUId)
            await this.$router.push({name: 'Job'})
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Oferta declinada exitosamente',
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      })
    },
    async SubmitApplication() {
      if (this.$store.state.User.user.cvUrl) {
        try {
        const history = {
          title : `${this.offerData.name}`,
          description :  `Has aplicado a la oferta laboral ${this.offerData.name}`,
          url : "/empleo",
          district : "Bolsa de trabajo"
        }
        await historyService.createHistory(history);
          await jobsService.applyJob(this.$route.params.id);
          await this.$router.push({name: 'Job'})
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Aplicación realizada exitosamente',
            position: 'is-bottom',
            type: 'is-success'
          })
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      } else {
        this.$buefy.dialog.confirm({
          title: 'Cargar hoja de vida',
          message: `<p> Actualmente no tienes una hoja de vida en tu perfil en la Ciudadela Universitaria Digital.</p> 
          <p class="my-5">Para postularte en una Oferta, debes cargar una hoja de vida.</p>`,
          confirmText: 'Ir a cargar hoja de vida',
          cancelText: 'Cancelar',
          onConfirm: () => {
            try {
              this.$router.push({name: 'Job'})
            } catch (e) {
              this.$buefy.toast.open({
                duration: 5000,
                message: e.response.data.message,
                position: 'is-bottom',
                type: 'is-danger'
              })
            }
          }
        })
      }
    },
    async getSpecificJob() {
      try {
        const response = await jobsService.getSpecificJob(this.$route.params.id);
        this.offerData = response;
        if (this.role !== roleName.ENTREPRENEUR) {
          this.jobOfferApplication =  await jobsService.getSpecificJobsProcess(this.$route.params.id, this.$store.getters.getUId)
          this.applicant = true;
        }
      } catch (e) {
        console.error('error', e);
      }
    },
    async deleteSpecificJob() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar oferta laboral',
        message: '¿Estás seguro que quieres eliminar esta oferta de la Ciudadela?',
        confirmText: 'Aceptar',
        cancelText: 'Cancelar',
        onConfirm: async () => {
          try {
            await jobsService.deleteJob(this.$route.params.id)
            await this.$router.push({name: 'Job'})
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Oferta eliminada exitosamente',
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      })
    }
  },
  mounted() {
    this.getSpecificJob();
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if(state.User && state.User.user) {
        this.role = state.User.user.role.name;
      }
    })
  },
}
</script>

<style scoped lang="scss" src="./ViewOffer.scss">

</style>
